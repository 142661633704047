<template>
  <div v-if="enableWarningMessage" class="flex w-full justify-center gap-2 bg-error-400 p-1">
    <div
      class="w-auto select-none rounded-2xl bg-warning-50 px-2 py-px text-xs leading-5 text-warning-700"
    >
      Upgrades in progress. Feature usage may be limited.
    </div>
  </div>
  <div
    class="hidden w-screen sm:block"
    :class="{
      'h-screen': !enableWarningMessage,
      'h-[calc(100vh-30px)]': enableWarningMessage
    }"
    :style="[brandingStyles]"
  >
    <router-view v-slot="{ Component }">
      <transition
        appear
        mode="out-in"
        enter-active-class="transition ease-out duration-200"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition ease-in duration-200"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="isLoading"
          class="flex h-full w-full flex-col items-center justify-start gap-4 overflow-hidden"
        >
          <img src="@/assets/Loader.gif" class="mt-20 w-40" alt="Loading the app" />
          <section v-show="showLogoutMsg" class="flex flex-col items-center justify-start gap-4">
            <span class="text-base font-medium text-gray-500">
              Having trouble logging in? Log out and try logging in again. Apologies for the
              inconvenience.
            </span>
            <BaseButton color="blue-light" @click="logout">Log out</BaseButton>
          </section>
        </div>
        <div v-else class="relative flex h-full">
          <MainToolbar v-if="isLoggedIn && !hideNavigation && route.name !== 'Plan View'" />

          <!-- Content area -->
          <div id="content-area" class="flex flex-1 flex-col overflow-hidden">
            <!-- Main content -->
            <div class="flex flex-1 items-stretch overflow-hidden">
              <main class="flex-1 overflow-y-auto">
                <component :is="Component" :key="route.fullPath" />
              </main>
            </div>
          </div>

          <!-- Inactivity popup -->
          <InactivityOverlay v-if="isLoggedIn && !isLoading && monitorForInactivity" />
        </div>
      </transition>
    </router-view>

    <CalendlyModal />
  </div>

  <MobileLock class="sm:hidden" />

  <!-- enable for debugging -->
  <VueQueryDevtools button-position="bottom-left" />

  <HierarchyContext v-if="isLoggedIn && boardId" :board-id="boardId" />
</template>

<script setup>
import BaseButton from '@/components/BaseButton.vue'
import MainToolbar from '@/components/MainToolbar.vue'
import useBoard from '@/hooks/use-board.js'
import 'firebase/auth'
import acceptPlanInvitation from '@/services/acceptPlanInvitationForNewUser.service.js'
import { setAPIUrl } from '@/services/api.service'
import * as Sentry from '@sentry/browser'
import firebase from 'firebase/compat/app'
import { computed, defineAsyncComponent, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useIdle } from '@vueuse/core'
import 'firebase/compat/auth'
import { toCSS } from '@/utils/brandingToCSS'
import { VueQueryDevtools } from '@tanstack/vue-query-devtools'
import HierarchyContext from '@/contexts/HierarchyContext.vue'

const InactivityOverlay = defineAsyncComponent(() => import('@/components/InactivityOverlay.vue'))
const CalendlyModal = defineAsyncComponent(() => import('@/components/CalendlyModal.vue'))
const MobileLock = defineAsyncComponent(() => import('@/components/MobileLock.vue'))

const { commit, getters, dispatch } = useStore()
const router = useRouter()
const route = useRoute()
const { boardId } = useBoard()
// this has to be attached at the root so that the activity time is updated correctly - we want to consider the activity accross the whole app not just of a component
const { lastActive } = useIdle()

const isLoading = computed(() => getters.isAppLoading)
const isLoggedIn = computed(() => getters.isLoggedIn)
const showLogoutMsg = ref(false)
const hideNavigation = computed(() => {
  return [
    'Home',
    'WeeeHome',
    'SignUp',
    'SharedPlan',
    'No Access',
    'VerifyEmail',
    'SetupUser',
    'Scenario Slide Pack'
  ].includes(route.name)
})

const enableWarningMessage = computed(() =>
  [
    'bd_hyICp4OCdoqRcl3jBDWUu' // Nestle
  ].includes(boardId.value)
)

const brandingStyles = computed(() => {
  const branding = getters['branding/branding']

  return branding ? toCSS(branding) : {}
})

watch(boardId, () => {
  dispatch('branding/fetch', { boardId: boardId.value })
})

const monitorForInactivity = computed(
  () => !['Home', 'ForgotPassword', 'SignUp', 'LoginRedirect'].includes(route.name)
)

watch(
  () => lastActive.value,
  () => localStorage.setItem('lastActive', lastActive.value)
)

const tokenRefreshInterval = ref(null)
let timer = null

/**
 * Starts the loading process by changing the isAppLoading state to true and setting a timer to show a logout message after 5 seconds.
 */
const startLoading = async () => {
  await dispatch('changeIsAppLoading', true)
  timer = setTimeout(() => {
    showLogoutMsg.value = true
  }, 5000)
}

/**
 * Stops the loading process by clearing the timer, changing the isAppLoading state to false, and hiding the logout message.
 */
const stopLoading = async () => {
  clearTimeout(timer)
  await dispatch('changeIsAppLoading', false)
  showLogoutMsg.value = false
}

/**
* Watches the isLoading state and performs actions based on its value.
* If the loading is false, it clears the timer and hides the logout message.
@param {boolean} newValue - The new value of the isLoading state.
*/
watch(isLoading, (newValue) => {
  if (!newValue) {
    clearTimeout(timer)
    showLogoutMsg.value = false
  }
})

/**
 * Initializes user tracking for analytics and monitoring services.
 */
const setupTracking = function (userObj) {
  window.mixpanel.identify(userObj.uid)
  window.mixpanel.people.set({
    $email: userObj.email, // only reserved properties need the $
    creationTime: userObj.metadata.creationTime,
    lastSignInTime: userObj.metadata.lastSignInTime
  })
  window.mixpanel.track('login')
  window.mixpanel.set_group('company', userObj.email.split('@')[1])
  if (window.ga4) {
    window.ga4.setUserProperties({
      company: userObj.email.split('@')[1],
      email: userObj.email,
      id: userObj.uid
    })
  }

  if (window.smartlook) {
    window.smartlook('identify', userObj.uid, {
      name: userObj.displayName,
      email: userObj.email
    })
  }


  Sentry.getGlobalScope().setUser({
    id: userObj.uid,
    email: userObj.email,
    username: userObj.displayName
  })
}

onMounted(async () => {
  window.mixpanel.track('app_open')
  await startLoading()

  //TODO: move to auth service, app.vue shouldn't care about this
  //TODO: does it run when token is active?
  firebase.auth().onAuthStateChanged(async (userObj) => {
    if (!userObj) {
      try {
        await firebase.auth().getRedirectResult()
      } catch (error) {
        commit('setSignInError', error)
      } finally {
        await stopLoading()
        if (route.meta.requiresAuth) {
          window.location.replace('/')
        }
      }

      return
    }

    await dispatch('setUserAuth', userObj)
    setupTracking(userObj)

    setAPIUrl()

    if (!userObj.emailVerified) {
      await stopLoading()
      router.push({ path: '/setup-user' })
    }

    if (
      userObj.email?.includes('@sayweee.com') &&
      ![
        'rudy.zhang@sayweee.com',
        'victoria.peng@sayweee.com',
        'xueyan.kehon@sayweee.com',
        'christina.wu@sayweee.com'
      ].includes(userObj.email)
    ) {
      // Reroute Weee users to Weee dedicated home page
      await stopLoading()
      // await router.push({ name: 'WeeeHome' })
      window.location = 'https://sayweee.web.app/weeeHome'
      return
    }

    // Initialize Pylon
    window.pylon = {
      chat_settings: {
        app_id: import.meta.env.VITE_PYLON_APP_ID,
        email: userObj.email,
        name: userObj.displayName || userObj.email
      }
    }

    try {
      await Promise.allSettled([
        dispatch('fetchAllBoards', userObj.uid),
        dispatch('fetchPendingInvitations', {
          email: userObj.email
        }),
        dispatch('fetchPendingPlanInvitations', {
          email: userObj.email
        }),
        dispatch('cardContent/fetchSettings', userObj.uid)
      ])

      // const hasBoards = getters.boards?.filter((board) => board.masterBranch).length > 0
      const hasInvites = getters.hasPendingInvitations
      const hasPlanInvites = getters.hasUserPlanInvites

      if (hasPlanInvites) {
        await acceptPlanInvitation(userObj.email)
      } else if (hasInvites) {
        window.mixpanel.track('new_invited_user')
        await router.push({ name: 'Landing' })
      }

      //refresh token every 10 minutes
      tokenRefreshInterval.value = setInterval(
        async () => {
          const user = firebase.auth().currentUser
          if (user) await user.getIdToken(true)
        },
        10 * 60 * 1000
      )
    } catch (error) {
      commit('setSignInError', error)
      console.error('error occured in redirection logic', error)
    } finally {
      await stopLoading()
    }
  })
})

/*
 * Logs out the user by dispatching the 'logout' action, stopping the loading process, and resetting the application state.
 * It also redirects the user to the home page.
 */
const logout = async () => {
  window.mixpanel.track('app_logout_due_to_stuck_at_loading')
  await dispatch('logout')
  await stopLoading()

  if (window.Intercom) window.Intercom('shutdown')
  location.href = '/'
}

onBeforeUnmount(() => {
  clearInterval(tokenRefreshInterval.value)
})
</script>

<style>
body {
  /* without this line, the font resets to default */
  font-family: Inter, 'sans-serif';
}
</style>

import { computed, ref, unref } from 'vue'
import { createGlobalState } from '@vueuse/core'

const useGlobalStates = createGlobalState(() => {
  const isLoading = ref({})
  const hierarchies = ref({})
  const hierarchyData = ref({})
  const hierarchyPerPerson = ref({})
  const activePeopleIdsCache = ref({})

  return {
    isLoading,
    hierarchies,
    hierarchyData,
    hierarchyPerPerson,
    activePeopleIdsCache
  }
})

const useGlobalOptions = createGlobalState(() => {
  const options = ref({})

  return {
    options
  }
})

const activePeopleIdsLock = {}

/**
 * Hierarchy data store for the board. Using this hook doesn't trigger a fetch.
 * @param {string} viewKey - the key to map the data to. Could be a simple boardId, but for better specificity, it should include the view name.
 */
export function useHierarchyState(boardId, view = null) {
  if (!unref(boardId)) return {}

  const viewKey = computed(() => {
    if (!unref(view)) {
      return unref(boardId)
    }

    return `${unref(boardId)}-${unref(view)}`
  })

  const { isLoading, hierarchies, hierarchyData, hierarchyPerPerson, activePeopleIdsCache } =
    useGlobalStates()

  const setHierarchy = (hierarchy) => {
    hierarchies.value[unref(viewKey)] = Object.freeze(hierarchy)

    const hierarchyById = hierarchy.descendants().reduce((acc, node) => {
      acc[node.id] = node
      return acc
    }, {})

    hierarchyPerPerson.value[unref(viewKey)] = Object.freeze(hierarchyById)
  }

  const setHierarchyData = (data) => {
    hierarchyData.value[unref(viewKey)] = data
    if (!activePeopleIdsLock[unref(viewKey)]) {
      activePeopleIdsLock[unref(viewKey)] = true
      activePeopleIdsCache.value[unref(viewKey)] = computeActivePeopleIds()
      activePeopleIdsLock[unref(viewKey)] = false
    }
  }

  const setIsLoading = (value) => {
    isLoading.value[unref(viewKey)] = value
  }

  const hierarchy = computed(() => hierarchies.value[unref(viewKey)] || null)
  const hierarchyPerson = computed(() => hierarchyPerPerson.value[unref(viewKey)] || {})
  const hierarchyRawData = computed(() => hierarchyData.value[unref(viewKey)] || [])
  const getIsLoading = computed(() => isLoading.value[unref(viewKey)] || false)

  const computeActivePeopleIds = () => {
    const data = hierarchyRawData.value
    if (!data) return []

    const excludedIds = new Set(['_root', 'p_nomanagernode', 'p_filtered_nodes'])
    const result = new Array(data.length)
    let index = 0

    for (let i = 0; i < data.length; i++) {
      const person = data[i]
      if (!excludedIds.has(person.id) && !person.collapsed) {
        result[index++] = person.id
      }
    }

    result.length = index
    return result
  }

  const activePeopleIds = computed(() => {
    return activePeopleIdsCache.value?.[unref(viewKey)] || []
  })

  return {
    hierarchy,
    hierarchyRawData,
    setHierarchy,
    setHierarchyData,
    hierarchyPerson,
    activePeopleIds,
    setIsLoading,
    getIsLoading
  }
}

export function useHierarchyOptions(boardId, view = null) {
  const viewKey = computed(() => {
    if (!unref(view)) {
      return unref(boardId)
    }

    return `${unref(boardId)}-${unref(view)}`
  })

  const { options } = useGlobalOptions()

  const setOptions = (newOptions) => {
    options.value[unref(viewKey)] = newOptions
  }

  return {
    options,
    setOptions
  }
}

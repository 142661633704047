import { Fields } from '@/lib/PlanFields'
import { computed, ref, unref, watch } from 'vue'
import _ from 'lodash'
import store from '@/store/index'
import { useStorage } from '@vueuse/core'

const conditionalFields = [
  Fields.department,
  Fields.email,
  Fields.location,
  Fields.name,
  Fields.role
]

const defaultFields = [
  Fields.reportingLayers,
  Fields.groupByRole,
  Fields.level,
  Fields.managementCost,
  Fields.rollupSpanOfControl,
  Fields.vacancies
]

const calculatedFields = [
  Fields.reportingLayers,
  Fields.groupByRole,
  Fields.level,
  Fields.managementCost,
  Fields.rollupSpanOfControl,
  Fields.vacancies,
  Fields.planStatus
]

const scenarioFields = [Fields.salaryNewRole, Fields.plannedHireDate, Fields.planStatus]
const restrictedFields = [Fields.salary]
const conditionalSelectedFields = [Fields.name, Fields.role, Fields.department, Fields.location]
const defaultSelectedFields = [
  Fields.name,
  Fields.role,
  Fields.department,
  Fields.location,
  Fields.planStatus,
  Fields.salaryNewRole
]

const selectedFields = useStorage('selected-fields', [])

/**
 * Shared state of selected card fields to be displayed
 */
export default function useScenarioFields(boardId) {
  const mappedFields = computed(() => store.getters.mappedFields(unref(boardId)))
  const isMappedField = (field) => mappedFields.value.includes(field)

  /**
   * Set selected fields.
   * @param {Array} fields - Array of fields to set as selected.
   * @returns {void}
   */
  const setSelectedFields = (fields) => {
    selectedFields.value = [...new Set(fields)]
  }

  const enableField = (field) => {
    if (!selectedFields.value.includes(field)) {
      setSelectedFields([...selectedFields.value, field])
    }
  }

  /**
   * Toggles the selected fields based on the provided field.
   * If the field is already selected, it will be removed from the selected fields.
   * If the field is not selected, it will be added to the selected fields.
   * @param {string} field - The field to toggle.
   */
  const toggleField = (field, keepSelected) => {
    if (!keepSelected && selectedFields.value.includes(field)) {
      const newFields = selectedFields.value.filter((f) => f !== field)
      setSelectedFields(newFields)
    } else {
      enableField(field)
    }
  }

  /**
   * Check whether field is currently selected
   */
  const isSelected = (field) => {
    return selectedFields.value.includes(field)
  }

  /**
   * Reset state to default
   */
  const reset = () => {
    selectedFields.value = defaultSelectedFields.filter((field) =>
      conditionalSelectedFields.includes(field) ? isMappedField(field) : true
    )
  }

  const filteredFieldsByMapping = ref([])
  watch(
    () => mappedFields.value,
    (newValue, oldValue) => {
      if (newValue && !_.isEqual(newValue, oldValue)) {
        filteredFieldsByMapping.value = conditionalFields.filter((field) =>
          newValue.includes(field)
        )
      }
    },
    { immediate: true, deep: true }
  )

  return {
    conditionalFields,
    defaultSelectedFields,
    conditionalSelectedFields,
    filteredFieldsByMapping,
    defaultFields,
    scenarioFields,
    isSelected,
    selectedFields,
    setSelectedFields,
    toggleField,
    reset,
    restrictedFields,
    enableField,
    calculatedFields
  }
}

import axios from 'axios'
import { API_URL, getAuthHeader } from '@/services/api.service.js'
import { until, useDebounceFn } from '@vueuse/core'
import { groupBy, isArray } from 'lodash-es'
import { ref } from 'vue'
import { decompressJson } from '@/utils/Utils'

//no project context for now, fetch all types
export const getTypes = async ({ boardId }) => {
  try {
    const url = new URL(`${API_URL}/custom-fields/${boardId}`)

    const ret = await axios.get(url, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) {
      return ret.data
    } else if (ret.status === 404) return []
    else throw new Error(ret.statusText)
  } catch (error) {
    console.error(error)
    return null
  }
}

export const fetchValues = async ({ boardId }) => {
  const ret = await axios.get(`${API_URL}/custom-fields/${boardId}/values`, {
    headers: { ...(await getAuthHeader()) }
  })

  if (ret.status === 200) {
    return decompressJson(ret.data) || []

    // const { data, post, terminate } = useWebWorker(DecompressWorker)
    // post(ret.data)
    // await until(data).changed()
    // terminate()
    // return data.value || []
  } else throw new Error(ret.statusText)
}

export const fetchValuesForPerson = async ({ boardId, personId }) => {
  if (!boardId || !personId) throw new Error('boardId and personId are required')

  const ret = await axios.get(`${API_URL}/custom-fields/${boardId}/values/${personId}`, {
    headers: { ...(await getAuthHeader()) }
  })

  if (ret.status === 200) {
    return ret.data || []
  } else throw new Error(ret.statusText)
}

let batchedRequests = []
const responses = ref({})

const fetchValuesForPersons = useDebounceFn(async (boardId) => {
  const personIds = [...new Set(batchedRequests.map((obj) => obj.personId))]
  batchedRequests = []

  const ret = await axios.post(
    `${API_URL}/custom-fields/${boardId}/values-batch`,
    { data: { personIds } },
    { headers: { ...(await getAuthHeader()) } }
  )

  if (ret.status === 200) {
    const data = decompressJson(ret.data) || []
    // const { data, post, terminate } = useWebWorker(DecompressWorker)
    // post(ret.data)
    // await until(data).changed()
    // terminate()

    const result = groupBy(data, 'personId') || {}
    personIds.forEach((key) => {
      responses.value[key] = result[key] || []
    })
  } else throw new Error(ret.statusText)
}, 500)

async function addToQueue({ boardId, personId }) {
  batchedRequests.push({ boardId, personId })

  fetchValuesForPersons(boardId)
  await until(() => responses.value[personId]).toMatch((value) => isArray(value))

  const result = responses.value[personId]
  delete responses.value[personId]

  return result
}

export const fetchValuesForPersonBatched = async ({ boardId, personId }) => {
  if (!boardId || !personId) return []

  const result = await addToQueue({ boardId, personId })

  return result || []
}

export const filter = async ({ boardId, personIds, fields }) => {
  try {
    const ret = await axios.post(
      `${API_URL}/custom-fields/${boardId}/filter`,
      { data: { personIds, fields } },
      { headers: { ...(await getAuthHeader()) } }
    )

    if (ret.status === 200) {
      return decompressJson(ret.data) || []
      // const { data, post, terminate } = useWebWorker(DecompressWorker)
      // post(ret.data)
      // await until(data).changed()

      // terminate()
      // return data.value || []
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

export const getUniqueValues = async ({ boardId, projectId }) => {
  try {
    const url = new URL(`${API_URL}/custom-fields/${boardId}/unique-values`)

    if (projectId) {
      url.searchParams.set('projectId', projectId)
    }

    const ret = await axios.get(url, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) {
      return decompressJson(ret.data) || []
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

export const addOrUpdateField = async ({ boardId, fields }) => {
  const ret = await axios.post(
    `${API_URL}/custom-fields/${boardId}`,
    { data: { fields } },
    { headers: { ...(await getAuthHeader()) } }
  )

  if (ret.status === 200) {
    return ret.data
  } else throw new Error(ret.statusText)
}

export const bulkUpdate = async ({ boardId, peopleIds, values }) => {
  const ret = await axios.post(
    `${API_URL}/custom-fields/${boardId}/bulk-values`,
    { data: { values, peopleIds } },
    { headers: { ...(await getAuthHeader()) } }
  )

  if (ret.status === 200) {
    return ret.data
  } else throw new Error(ret.statusText)
}

export const addOrUpdateValues = async ({ boardId, fields }) => {
  try {
    const ret = await axios.post(
      `${API_URL}/custom-fields/${boardId}/values`,
      {
        data: { fields }
      },
      { headers: { ...(await getAuthHeader()) } }
    )

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

export const remove = async ({ boardId, fieldId }) => {
  try {
    const ret = await axios.delete(`${API_URL}/custom-fields/${boardId}/${fieldId}`, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

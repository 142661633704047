<template>
  <section class="flex items-center gap-2 bg-white">
    <slot name="options"></slot>
    <PopperTooltip v-for="view in views" :key="view.name" placement="bottom" hover open-delay="300">
      <template #content>
        {{ view.tooltipText }}
        <kbd
          v-if="view.keyboardShortcut"
          class="ml-1 rounded border border-gray-300 bg-gray-200 px-1 py-0.5 text-gray-900"
        >
          {{ view.keyboardShortcut }}
        </kbd>
      </template>
      <template v-if="!view.handler">
        <TooltipBaseLink
          v-if="!isPlanToolbar ? show(view.name) : true"
          v-slot="{ currentPath }"
          :path="path(view.name)"
          :disabled="disabled"
          :data-test="view.dataTest"
        >
          <component
            :is="view.icon"
            class="h-6 w-6"
            :class="{ 'text-bluedark-500': currentPath }"
          />
        </TooltipBaseLink>
      </template>
      <template v-else>
        <button
          :disabled="disabled"
          class="flex items-center justify-center rounded-lg px-[14px] py-2 text-sm font-medium transition-colors hover:bg-bluedark-50 hover:text-bluedark-700 disabled:text-gray-300"
          :class="{
            'bg-bluedark-50 text-bluedark-500 hover:bg-bluedark-100': currentView === view.name,
            'text-gray-500': currentView !== view.name
          }"
          :data-test="view.dataTest"
          @click="view.handler"
        >
          <component :is="view.icon" class="h-6 w-6" />
        </button>
      </template>
    </PopperTooltip>
  </section>
</template>

<script setup>
import { computed } from 'vue'
import TooltipBaseLink from '@/components/TooltipBaseLink.vue'
import useNavigation from '@/hooks/use-navigation'

defineProps({
  views: {
    type: Array,
    required: true
  },
  disabled: {
    type: Boolean,
    default: false
  },
  currentView: {
    type: String,
    required: false,
    default: ''
  },
  isPlanToolbar: {
    type: Boolean,
    required: false,
    default: false
  },
  projectId: {
    type: String,
    required: false,
    default: ''
  }
})

const { navigationConfig } = useNavigation()

const show = (menuItem) => computed(() => navigationConfig.value[menuItem]?.show || false).value
const path = (menuItem) => computed(() => navigationConfig.value[menuItem]?.url || '').value
</script>

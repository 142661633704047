import { useEventBus } from '@vueuse/core'

export const errorBus = useEventBus('error')
export const personUpdateBus = useEventBus('person-update')
export const commandsBus = useEventBus('commands')
export const bulkActionsBus = useEventBus('bulk-actions')
export const orgChartBus = useEventBus('org-chart')

export const BulkActions = {
  ManagerUpdated: 'manager-updated'
}

export const OrgChartActions = {
  ToggleExpand: 'toggle-expand',
  ExpandNode: 'expand-node',
  ToggleDeleteConfirmation: 'toggle-delete-confirmation',
  ExpandAll: 'expand-all',
  ExpandExecutives: 'expand-executives',
  ExpandUpToLevel: 'expand-up-to-level',
  OpenProfile: 'open-profile',
  ZoomIn: 'zoom-in',
  ZoomOut: 'zoom-out',
  CollapseAll: 'collapse-all',
  FitScreen: 'fit-screen',
  LayoutToggle: 'layout-toggle',
  HighlightPerson: 'highlight-person',
  CenterRoot: 'center-root',
  NodeDragEnd: 'node-drag-end',
  HireDateChange: 'hire-date-change',
  UndoDeleteManager: 'undo-delete-manager',
  ReplacePerson: 'replace-person',
  MarkAsRif: 'mark-as-rif',
  DeletePerson: 'delete-person',
  PastePerson: 'paste-person',
  DuplicatePerson: 'duplicate-person',
  AddSubordinate: 'add-subordinate',
  AddDottedLine: 'add-dotted-line',
  ToggleBackfill: 'toggle-backfill',
  MoveNodeLeft: 'move-node-left',
  MoveNodeRight: 'move-node-right',
  RemoveTeam: 'remove-team',
  PredictSalary: 'predict-salary',
  SalaryChange: 'edit-salary',
  DepartmentChange: 'department-change',
  LocationChange: 'location-change',
  RoleChange: 'role-change',
  NameChange: 'name-change',
  ExpandDefaultLevel: 'expand-default-level',
  SetLayout: 'set-layout'
}

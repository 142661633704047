import { createGlobalState } from '@vueuse/core'
import { readonly, ref } from 'vue'

export const modes = {
  all: 'all',
  hiresLeavers: 'hiresLeavers',
  executive: 'executive',
  changes: 'changes',
  overview: 'overview',
  dottedLines: 'dottedLines',
  approvedRoles: 'approvedRoles',
  managerView: 'managerView',
  rolesView: 'rolesView',
  managementCost: 'managementCost',
  organizationUnits: 'organizationUnits',
  summaryView: 'summary',
  savedView: 'savedView'
}

const useMode = createGlobalState(() => {
  const mode = ref(modes.all)
  const countSummaryMode = ref({})

  return {
    mode,
    countSummaryMode
  }
})

export default function useChartMode() {
  const { mode, countSummaryMode } = useMode()

  const setMode = (newMode) => {
    mode.value = newMode
  }

  const setCountSummaryMode = (newMode) => {
    countSummaryMode.value = newMode
  }

  return {
    mode: readonly(mode),
    setMode,
    countSummaryMode: readonly(countSummaryMode),
    setCountSummaryMode
  }
}

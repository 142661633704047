import { ACCESS_LEVELS_DICT } from '@/lib/Access.js'
import * as Sentry from '@sentry/browser'
import {
  cloneDeep,
  each,
  find,
  isArray,
  keyBy,
  union,
  unionBy,
  keys,
  pickBy,
  isEmpty
} from 'lodash-es'
import {
  updateAccessLevel,
  deleteBoard,
  getBoard,
  getBoards,
  getBoardMapping,
  updateBoard
} from '@/services/boards-api.service.js'
import { exemptMappingRequirement } from '@/lib/mapping.js'

const getDefaultState = () => {
  return {
    boards: [],
    fetchCount: 0,
    isFetching: false,
    boardMappings: {}
  }
}

const state = getDefaultState()

const mutations = {
  setBoards: (_state, boards) => {
    _state.boards = boards
    _state.fetchCount++
  },

  reset(_state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(_state, getDefaultState())
  },

  updateBoard(_state, { boardId, key, value }) {
    const clone = cloneDeep(_state.boards)
    each(clone, (obj) => {
      if (obj.boardId === boardId) {
        obj[key] = value
      }
    })
    _state.boards = clone
  },

  updateBoardMapping(_state, { boardId, mapping }) {
    _state.boardMappings[boardId] = mapping
  },

  setIsFetching(_state, isFetching) {
    _state.isFetching = isFetching
  },

  SET_COLOUR(_state, { boardId, content, filter, colour }) {
    const board = _state.boards.find((board) => board.boardId === boardId)
    board.theme = board.theme ?? {}
    board.theme[content] = board.theme[content] ?? {}
    board.theme[content][filter] = colour
  },
  SET_THEME(_state, { boardId, theme }) {
    if (theme) {
      _state.boards.find((board) => board.boardId === boardId).theme = theme
    }
  }
}

const getters = {
  isNestle: (_state) => (boardId) => {
    if (boardId === 'bd_hyICp4OCdoqRcl3jBDWUu') return true
    const board = _state.boards.find((board) => board.boardId === boardId)
    return board && board.originalBoardId === 'bd_hyICp4OCdoqRcl3jBDWUu'
  },
  getBoard: (_state) => (boardId) =>
    _state.boards?.find((board) => board.boardId === boardId) || null,
  getBoardMapping: (_state) => (boardId) => _state.boardMappings[boardId],
  boardMapping: (_, _getters) => (boardId) => _getters.getBoardMapping(boardId) || {},
  notMappedFields: (_, _getters) => (boardId) => {
    const mapping = _getters.boardMapping(boardId)
    return keys(
      pickBy(mapping?.mapping, (value, key) =>
        exemptMappingRequirement.includes(key) ? false : isEmpty(value)
      )
    )
  },
  mappedFields: (_, _getters) => (boardId) => {
    const mapping = _getters.boardMapping(boardId)
    return keys(
      pickBy(mapping?.mapping, (value, key) =>
        exemptMappingRequirement.includes(key) ? true : !isEmpty(value)
      )
    )
  },
  getAccessLevel:
    (_, _getters) =>
    ({ boardId, uid }) => {
      const board = _getters.getBoard(boardId)
      const levelRecord = board?.accessLevels?.find((level) => level.uid === uid)

      return levelRecord?.accessLevel || ACCESS_LEVELS_DICT.NO_ACCESS
    },
  boards: (_state) => _state.boards,
  isSampleData: (_state, _getters) => (boardId) => _getters.getBoard(boardId)?.sample,
  mainBoards: (_state) => _state.boards?.filter((board) => board.masterBranch) || [],
  scenarios: (_state) => (boardId) =>
    _state.boards?.filter(
      (board) => !board.masterBranch && board.originalBoardId === boardId && !board?.deletedAt
    ) || [],
  defaultBoard: (_, _getters) => _getters.mainBoards[0],
  accessLevels: (_, _getters) => (boardId) => _getters.getBoard(boardId)?.accessLevels,
  accessLevelsByUid: (_, _getters) => (boardId) => keyBy(_getters.accessLevels(boardId), 'uid'),
  isFetchingBoards: (_state) => _state.isFetching,
  isMainBoard: (_, _getters) => (boardId) => {
    const board = _getters.getBoard(boardId)
    return (board.originalBoardId ?? board.boardId) === boardId
  },
  getConfigData: (_, _getters) => (boardId) => _getters.getBoard(boardId)?.configData || {}
}

const actions = {
  async checkBoardAccess(context, { boardId }) {
    try {
      await getBoard({ boardId })
      return true
    } catch (e) {
      // Access not possible
      return false
    }
  },
  async fetchAllBoards(context, userId) {
    let count = 0
    const maxTries = 2
    while (count <= maxTries) {
      try {
        context.commit('setIsFetching', true)
        const boards = await getBoards({ userId, forceTokenRefresh: count > 0 })
        context.commit('setBoards', boards)
        break
      } catch (error) {
        if (count === maxTries) throw error
      } finally {
        count++
        context.commit('setIsFetching', false)
      }
    }
  },
  async fetchBoardMapping(context, boardId) {
    try {
      // check for mapping for the passed board Id
      let mappingInfo = await getBoardMapping({ boardId })
      if (!mappingInfo) throw new Error('No mapping found')

      context.commit('updateBoardMapping', { boardId, mapping: mappingInfo })
    } catch (error) {
      console.log(error)
    }
  },
  async updateAccessLevel(context, { targetUid, newAccessLevel, boardId }) {
    try {
      //MIGRATION: is this more like POST /board/:boardId to update the document?
      //TODO: do we need to fetch again here or just use the board from store?
      const { accessLevels, owners } = await updateAccessLevel({
        targetUid,
        newAccessLevel,
        boardId
      })

      // update the store because we are not fetching again from the server
      context.commit('updateBoard', {
        boardId,
        key: 'accessLevels',
        value: accessLevels
      })
      context.commit('updateBoard', {
        boardId,
        key: 'owners',
        value: owners
      })
    } catch (err) {
      console.log('err in updateAccessLevel', err)
      throw err
    }
  },
  reset({ commit }) {
    commit('reset')
  },
  updateBoard(context, { boardId, key, value }) {
    context.commit('updateBoard', { boardId, key, value })
  },
  async updateAndSyncBoard(context, { boardId, key, value }) {
    context.commit('updateBoard', { boardId, key, value })
    await context.dispatch('submitBoard', { boardId })
  },
  async submitBoard(context, { boardId }) {
    const targetBoard = find(context.state.boards, (board) => {
      return board.boardId === boardId
    })

    try {
      if (!targetBoard) return

      await updateBoard({
        boardId,
        payload: {
          ...targetBoard
        }
      })
    } catch (err) {
      console.log('err in submitBoard', err)
    }
  },
  async deleteClonedBoard(context, { boardId }) {
    const targetBoard = context.state.boards.find(
      (board) => board.boardId === boardId && !board.masterBranch
    )

    if (!targetBoard) return

    // Delete board, people, compensations, plan_invitations, and plan_logs
    await deleteBoard({ boardId })
  },
  async addCollaborators({ commit, getters }, { boardId, employees, setAsAdmin = false }) {
    try {
      const board = getters.getBoard(boardId)
      const localCollaborators = board?.collaborators || []
      const localOwners = board?.owners || []
      const localAccessLevels = board?.accessLevels || []

      const currentBoard = await getBoard({ boardId })
      const currentAccessLevels = currentBoard.accessLevels || []
      const currentCollaborators = currentBoard.collaborators || []
      const currentOwners = currentBoard.owners || []

      const personIds = employees
        .map((employee) => employee.personId)
        .filter((employee) => employee)
      const userIds = employees.map((employee) => employee.uid)
      // for submitted plans add collaborators as admin
      const accessLevels = employees.map((employee) => ({
        uid: employee.uid,
        accessLevel: setAsAdmin ? ACCESS_LEVELS_DICT.ADMIN : employee.accessLevel
      }))

      const updatedAccessLevels = unionBy(
        accessLevels,
        currentAccessLevels,
        localAccessLevels,
        'uid'
      )
      const updatedCollaborators = union(currentCollaborators, localCollaborators, personIds)
      const updatedOwners = union(currentOwners, localOwners, userIds)

      await updateBoard({
        boardId,
        payload: {
          collaborators: updatedCollaborators,
          owners: updatedOwners,
          accessLevels: updatedAccessLevels
        }
      })

      commit('updateBoard', {
        boardId,
        key: 'collaborators',
        value: updatedCollaborators
      })

      commit('updateBoard', {
        boardId,
        key: 'owners',
        value: updatedOwners
      })

      commit('updateBoard', {
        boardId,
        key: 'accessLevels',
        value: updatedAccessLevels
      })
    } catch (e) {
      console.log('error while adding collaborator', e)
      Sentry.captureException(e)
    }
  },
  async removeCollaborators({ commit, getters }, { boardId, employees }) {
    try {
      const personIds = employees.map((employee) => employee.personId)
      const userIds = employees.map((employee) => employee.uid)

      const board = getters.getBoard(boardId)
      const collaborators =
        board?.collaborators.filter((collaborator) => !personIds.includes(collaborator)) || []
      const owners = board?.owners.filter((owner) => !userIds.includes(owner)) || []
      const accessLevels = board.accessLevels.filter(
        (accessLevel) => !userIds.includes(accessLevel.uid)
      )

      await updateBoard({
        boardId,
        payload: {
          collaborators,
          accessLevels,
          owners
        }
      })

      commit('updateBoard', {
        boardId,
        key: 'collaborators',
        value: collaborators
      })

      commit('updateBoard', {
        boardId,
        key: 'owners',
        value: owners
      })
    } catch (e) {
      console.log(e)
    }
  },
  async toggleSyncWithOrg({ commit, rootGetters }, { boardId, isSyncEnabled }) {
    try {
      commit('updateBoard', { boardId, key: 'isSyncEnabled', value: isSyncEnabled })

      await updateBoard({
        boardId,
        payload: {
          isSyncEnabled,
          updatedBy: rootGetters.uid
        }
      })
    } catch (err) {
      Sentry.captureException(err)
    }
  },
  async addBoardConfig(context, { boardId, configData }) {
    try {
      const board = context.getters.getBoard(boardId)
      const newConfigs = []
      if (!board) return
      if (!configData) return

      configData.id = crypto.randomUUID()

      if (board.configData && typeof board.configData === 'object' && !isArray(board.configData)) {
        console.log('has object')
        //upgrade old field
        const existingConfig = board.configData
        existingConfig.name = 'Saved config'
        existingConfig.id = crypto.randomUUID()

        newConfigs.push(existingConfig)
      } else if (board.configData && isArray(board.configData)) {
        newConfigs.push(...board.configData)
      }

      newConfigs.push(configData)

      await context.dispatch('updateAndSyncBoard', {
        boardId,
        key: 'configData',
        value: newConfigs
      })

      return configData
    } catch (err) {
      Sentry.captureException(err)
    }
  },
  async removeBoardConfig(context, { boardId, configId }) {
    try {
      const board = context.getters.getBoard(boardId)
      if (!board) return

      const newConfigs = board.configData.filter((config) => config.id !== configId)

      await context.dispatch('updateAndSyncBoard', {
        boardId,
        key: 'configData',
        value: newConfigs
      })
    } catch (err) {
      Sentry.captureException(err)
    }
  },
  async updateBoardName({ commit }, { boardId, boardName }) {
    await updateBoard({
      boardId,
      payload: {
        boardName,
        companyName: boardName
      }
    })

    commit('updateBoard', {
      boardId,
      key: 'boardName',
      value: boardName
    })

    commit('updateBoard', {
      boardId,
      key: 'companyName',
      value: boardName
    })
  },
  setColour(context, { boardId, content, filter, colour }) {
    context.commit('SET_COLOUR', { boardId, content, filter, colour })
  },
  async updateTheme(context, { boardId, theme }) {
    const boardData = await getBoard({
      boardId
    })
    context.commit('SET_THEME', { boardId, theme })
    await updateBoard({ boardId, payload: { ...boardData, theme } })
  }
}

export default {
  state,
  mutations,
  getters,
  actions,
  modules: {}
}

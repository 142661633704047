import axios from 'axios'
import { API_URL, getAuthHeader } from '@/services/api.service.js'

export async function getSettings({ boardId }) {
  // GET /settings/:boardId
  try {
    const ret = await axios.get(`${API_URL}/settings/${boardId}`, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return {}
  }
}

export async function updateSettings({ boardId, settings }) {
  try {
    const ret = await axios.post(
      `${API_URL}/settings/${boardId}`,
      { data: { settings } },
      { headers: { ...(await getAuthHeader()) } }
    )

    if (ret.status === 200 || ret.status === 201) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

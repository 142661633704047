export const Fields = {
  department: 'department',
  location: 'officeLocation',
  email: 'email',
  salary: 'salary',
  salaryNewRole: 'salary-newrole',
  name: 'name',
  plannedHireDate: 'planned-hire-date',
  planStatus: 'scenario-status',
  level: 'level',
  reportingLayers: 'reporting-layers',
  groupByRole: 'group-by-role',
  rollupSpanOfControl: 'rollup-span-of-control',
  managementCost: 'management-cost',
  vacancies: 'vacancies',
  role: 'role'
}

export const FieldsLabels = {
  [Fields.name]: 'Name',
  [Fields.department]: 'Department',
  [Fields.location]: 'Location',
  [Fields.email]: 'Email',
  [Fields.salary]: 'Salary',
  [Fields.salaryNewRole]: 'Salary (new role)',
  [Fields.plannedHireDate]: 'Hire date (new role)',
  [Fields.planStatus]: 'Scenario status',
  [Fields.level]: 'Level',
  [Fields.groupByRole]: 'Group by: role',
  [Fields.rollupSpanOfControl]: 'Rollup span of control',
  [Fields.managementCost]: 'Management cost',
  [Fields.vacancies]: 'Vacancies',
  [Fields.reportingLayers]: 'Reporting layers',
  [Fields.role]: 'Role'
}

import { computed, unref } from 'vue'
import { FilterType } from '@/lib/OrgChartFilters'
import { createGlobalState, useStorage, watchDebounced } from '@vueuse/core'
import { useStore } from 'vuex'
import useMeta from '@/hooks/use-meta'
import { useCustomFields } from '@/hooks/use-custom-fields'
import { Types } from '@/lib/CustomFields.js'
import { MappingDict } from '@/lib/mapping'
import { useFilterStore, useFilterValuesStore } from '@/pinia/use-filter-store'
import { storeToRefs } from 'pinia'
import useBoard from '@/hooks/use-board'
import { useHierarchyState } from '@/hooks/use-hierarchy'
import { BaseHierarchy } from '@/lib/BaseHierarchy'
import { uniqBy } from 'lodash-es'

export const defaultOption = { name: 'Show all', type: '', id: '__default' }

const useState = createGlobalState(() => {
  const selectedFilters = useStorage('selected-filters', {})

  const setSelectedFilters = (key, newFilters) => {
    selectedFilters.value[key] = newFilters
  }

  return {
    selectedFilters,
    setSelectedFilters
  }
})

export const isDefault = (filters) =>
  (filters?.length === 1 && filters[0].id === '__default') || filters?.length === 0

export const isSelectedImpl = (filters, filter) => filters?.some((f) => f.id === filter.id)
export const setFilterImpl = (selectedFilters, newFilter) => {
  if (newFilter.type === '') {
    // If "Show all" is selected, clear all other selections
    return [defaultOption]
  }

  // If any other filter is selected, clear "Show all" and handle the rest
  let result = selectedFilters?.filter((item) => item.type !== '')

  if (isSelectedImpl(result, newFilter)) {
    result = result?.filter((item) => item.id !== newFilter.id)
  } else {
    result?.push(newFilter)
  }

  // If no filters are selected, set "Show all"
  if (result?.length === 0) {
    result = [defaultOption]
  }

  return result
}
export const resetFieldFiltersImpl = (selectedFilters, type) => {
  let result = selectedFilters?.filter((item) => item.typeId !== type && item.type !== type)

  if (result?.length === 0) {
    result = [defaultOption]
  }
  return result
}

export function useSelectedFilters(boardId, view = null) {
  const filterStore = useFilterStore()
  const { filterHistory } = storeToRefs(filterStore)
  const { updateFilterHistory } = filterStore
  const { selectedFilters: selectedFiltersPerView, setSelectedFilters: setFiltersState } =
    useState()

  const filterKey = computed(() =>
    unref(view) ? `${unref(boardId)}-${unref(view)}` : unref(boardId)
  )

  const selectedFilters = computed(() => selectedFiltersPerView.value[filterKey.value] || [])

  const setSelectedFilters = (newFilters) => {
    setFiltersState(filterKey.value, newFilters)
  }

  const resetFilters = () => {
    setSelectedFilters([defaultOption])
  }

  if (!selectedFilters.value) {
    resetFilters()
  }

  /**
   * Checks if an option is selected based on its ID.
   *
   * @param {Object} option - The option to check.
   * @param {string} option.id - The ID of the option.
   * @returns {boolean} - True if the option is selected, false otherwise.
   */
  const isSelected = (filter) => {
    return isSelectedImpl(selectedFilters.value, filter)
  }

  /**
   * Sets the selected filters based on the option selected by the user.
   * If "Show all" is selected, clears all other selections.
   * If any other filter is selected, clears "Show all" and handles the rest.
   *
   * @param {Object} option - The option selected by the user.
   * @param {string} option.type - The type of the option selected.
   * @param {string} option.id - The ID of the option selected.
   */
  const setFilter = (option) => {
    setSelectedFilters(setFilterImpl(selectedFilters.value, option))
  }

  const setManagerFilter = (managerId, managerName) => {
    const existingManagerFilter = selectedFilters.value?.filter(
      (filter) => filter.type === FilterType.Manager
    )

    if (existingManagerFilter.length === 1 && existingManagerFilter[0].personId === managerId) {
      setSelectedFilters(filterHistory.value[filterKey][managerId])
      return
    }

    updateFilterHistory(filterKey, managerId, selectedFilters.value)

    const existingFilters = selectedFilters.value?.filter(
      (filter) => filter.type !== FilterType.Manager
    )

    const managerFilter = {
      name: managerName,
      personId: managerId,
      type: FilterType.Manager,
      id: `manager-${managerId}`
    }

    existingFilters.push(managerFilter)
    setSelectedFilters(existingFilters)
  }

  /**
   * Construct the complex filter from existing base filters
   */
  const setComplexFilter = (filter) => {
    if (filter.type === 'managers') {
      //find managers with span of control over the value
      const filterToSet = filter.managers.map((manager) => {
        return {
          name: manager.name,
          type: FilterType.Manager,
          personId: manager.personId,
          id: manager.personId
        }
      })

      setSelectedFilters(filterToSet)
    }
  }

  const resetFieldFilters = (typeId) => {
    setSelectedFilters(resetFieldFiltersImpl(selectedFilters.value, typeId))
  }

  const selectedCountByFilterCategory = computed(() => {
    if (isDefault(selectedFilters.value)) return {}

    return selectedFilters.value.reduce((acc, filter) => {
      if (filter.typeId) {
        if (!acc[filter.typeId]) acc[filter.typeId] = []
        acc[filter.typeId].push(filter)
      } else {
        if (!acc[filter.type]) acc[filter.type] = []
        acc[filter.type].push(filter)
      }
      return acc
    }, {})
  })

  return {
    selectedFilters,
    setFilter,
    setSelectedFilters,
    setManagerFilter,
    setComplexFilter,
    resetFieldFilters,
    resetFilters,
    isSelected,
    selectedCountByFilterCategory
  }
}

const getCacheKey = (boardId, projectId) => `${boardId}-${projectId}`

// Memoized function to create and cache the values computed property
const createCachedValuesAndCategories = (boardId, projectId) => {
  const filterValuesStore = useFilterValuesStore()
  const { values, categories, unfilteredCategories } = storeToRefs(filterValuesStore)

  const cacheKey = getCacheKey(unref(boardId), unref(projectId))
  const computedValues = computed(() => values.value?.[cacheKey] || [])
  const computedCategories = computed(() => categories.value?.[cacheKey] || [])
  const computedUnfilteredCategories = computed(() => unfilteredCategories.value?.[cacheKey] || [])

  if (values.value?.[cacheKey] && categories.value?.[cacheKey] && unfilteredCategories.value?.[cacheKey]) {
    return { values: computedValues, categories: computedCategories, unfilteredCategories: computedUnfilteredCategories }
  }

  const { getters } = useStore()
  const { boardId: mainBoardId } = useBoard()
  const { departments, roles, managers, locations, states } = useMeta(boardId, projectId)
  const { uniqueValues, types: scenarioCustomFieldTypes } = useCustomFields(boardId, projectId)
  const { types: customFieldTypes } = useCustomFields(mainBoardId)
  const { hierarchyPerson } = useHierarchyState(mainBoardId, BaseHierarchy)

  const sensitiveDataFields = computed(() => getters.sensitiveDataFields(unref(boardId)))
  const workforceStatusList = computed(() => ['approved', 'submitted', 'current employees'])
  const notMappedFields = computed(() => getters.notMappedFields(boardId))
  const fieldSettings = computed(() => getters.getFieldVisibility ?? {})

  const sensitiveDataValues = computed(() => {
    const ret = {}
    const sensitiveData = getters.sensitiveData(unref(boardId))

    for (let j = 0; j < sensitiveData.length; j++) {
      const obj = sensitiveData[j]
      for (let i = 0; i < obj.data.length; i++) {
        if (!(obj.data[i].field in ret)) {
          ret[obj.data[i].field] = new Set()
        }
        ret[obj.data[i].field].add(obj.data[i].value)
      }
    }

    return ret
  })

  const allCustomFieldTypes = computed(() => {
    const allTypes = [...(scenarioCustomFieldTypes.value ?? []), ...(customFieldTypes.value ?? [])]
    return uniqBy(allTypes, 'id')
  })

  const computeValues = () => {
    const result = {}
    const notMappedFieldsValue = notMappedFields.value

    // Helper function to add values to result
    const addValues = (type, dataArray, prefix) => {
      if (notMappedFieldsValue?.includes(MappingDict[type])) return

      const values = new Array(dataArray.length + 1)
      let index = 0

      for (let i = 0; i < dataArray.length; i++) {
        const item = dataArray[i]
        if (item) {
          values[index++] = {
            name: item,
            type: FilterType[type],
            id: `${prefix}-${item}`
          }
        }
      }

      // Add '(blanks)' option
      values[index] = { name: '(blanks)', type: FilterType[type], id: `${prefix}-blanks` }

      result[FilterType[type]] = values
    }

    addValues('Department', departments.value, 'department')
    addValues('Role', roles.value, 'role')
    addValues('Location', locations.value, 'location')

    const rootDepth = hierarchyPerson.value?.['_root']?.depth + 1
    const rootHeight = hierarchyPerson.value?.['_root']?.height

    // Managers
    if (!notMappedFieldsValue?.includes(MappingDict.managerId)) {
      result[FilterType.Manager] = managers.value.map((manager) => ({
        name: manager.name || manager.role || manager.email || 'Manager',
        personId: manager.personId,
        type: FilterType.Manager,
        id: `manager-${manager.personId}`
      }))
    }

    result[FilterType.Level] = [
      ...Array.from({ length: rootHeight }, (_, i) => i + rootDepth).map((level) => ({
        name: level,
        type: FilterType.Level,
        id: `level-${rootDepth > 1 ? level - (rootDepth - 1) : level}`
      }))
    ]

    // Scenario Status
    result[FilterType.ScenarioStatus] = [
      ...states.value.map((status) => ({
        name: status,
        type: FilterType.ScenarioStatus,
        id: `status-${status}`
      })),
      { name: '(blanks)', type: FilterType.ScenarioStatus, id: 'status-blanks' },
      { name: 'No Change', type: FilterType.ScenarioStatus, id: 'status-no-change' }
    ]

    // Workforce Status
    result[FilterType.WorkforceStatus] = workforceStatusList.value
      .map((status) => ({
        name: status,
        type: FilterType.WorkforceStatus,
        id: `workforceStatus-${status}`
      }))
      .sort((a, b) => a.name.localeCompare(b.name))

    allCustomFieldTypes.value.forEach(({ id: typeId, type }) => {
      const uniqueValuesForType = uniqueValues.value?.[typeId] || []
      const options = new Array(uniqueValuesForType.length + 1)
      let index = 0

      for (let i = 0; i < uniqueValuesForType.length; i++) {
        const fieldValue = uniqueValuesForType[i]
        if (fieldValue && type !== Types.Succession) {
          options[index++] = {
            name: fieldValue,
            type: FilterType.CustomFieldV2,
            id: `customFieldV2-${fieldValue}`,
            typeId
          }
        }
      }

      options[index] = {
        name: '(blanks)',
        type: FilterType.CustomFieldV2,
        id: `customFieldV2-${typeId}-blanks`,
        typeId
      }

      options.length = index + 1
      options.sort((a, b) => a.name.localeCompare(b.name))

      result[typeId] = options
    })

    // Sensitive Data
    sensitiveDataFields.value.forEach((sensitiveDataField) => {
      const fieldValues = sensitiveDataValues.value[sensitiveDataField] || new Set()
      const options = new Array(fieldValues.size + 1)
      let index = 0

      fieldValues.forEach((field) => {
        options[index++] = {
          name: field,
          type: FilterType.SensitiveData,
          id: `sensitiveData-${field}`,
          typeId: `${FilterType.SensitiveData}_${sensitiveDataField}`
        }
      })

      options[index] = {
        name: '(blanks)',
        type: FilterType.SensitiveData,
        id: `sensitiveData-${sensitiveDataField}-blanks`,
        typeId: `${FilterType.SensitiveData}_${sensitiveDataField}`
      }

      options.sort((a, b) => a.name.localeCompare(b.name))
      result[`${FilterType.SensitiveData}_${sensitiveDataField}`] = options
    })

    return result
  }

  const computeCategories = (computedValues) => {
    const base = []
    const notMappedFieldsValue = notMappedFields.value

    if (!notMappedFieldsValue?.includes(MappingDict.department)) {
      base.push({
        name: 'Department',
        type: FilterType.Department,
        id: FilterType.Department
      })
    }

    base.push({
      name: 'Level',
      type: FilterType.Level,
      id: FilterType.Level
    })

    if (!notMappedFieldsValue?.includes(MappingDict.officeLocation)) {
      base.push({
        name: 'Location',
        type: FilterType.Location,
        id: FilterType.Location
      })
    }

    if (!notMappedFieldsValue?.includes(MappingDict.managerId)) {
      base.push({
        name: 'Manager',
        type: FilterType.Manager,
        id: FilterType.Manager
      })
    }

    base.push({
      name: 'Review',
      type: FilterType.WorkforceStatus,
      id: FilterType.WorkforceStatus
    })

    if (!notMappedFieldsValue?.includes(MappingDict.role)) {
      base.push({
        name: 'Role',
        type: FilterType.Role,
        id: FilterType.Role
      })
    }

    if (boardId !== mainBoardId.value) {
      base.push({
        name: 'Scenario Status',
        type: FilterType.ScenarioStatus,
        id: FilterType.ScenarioStatus
      })
    }

    const customFieldsValue = allCustomFieldTypes.value
    const customFields = customFieldsValue ? new Array(customFieldsValue.length) : []

    for (let i = 0; i < customFieldsValue?.length || 0; i++) {
      const { name, id, order } = customFieldsValue[i]
      customFields[i] = {
        name,
        id,
        order: order || 0,
        type: FilterType.CustomFieldV2
      }
    }

    const sensitiveDataFieldsValue = sensitiveDataFields.value
    const _sensitiveDataFields = new Array(sensitiveDataFieldsValue.length)

    for (let i = 0; i < sensitiveDataFieldsValue.length; i++) {
      const field = sensitiveDataFieldsValue[i]
      _sensitiveDataFields[i] = {
        name: field,
        type: FilterType.SensitiveData,
        id: `${FilterType.SensitiveData}_${field}`
      }
    }

    const categories = new Array(base.length + customFields.length + _sensitiveDataFields.length)
    let index = 0

    for (let i = 0; i < base.length; i++) {
      categories[index++] = base[i]
    }
    for (let i = 0; i < customFields.length; i++) {
      categories[index++] = customFields[i]
    }
    for (let i = 0; i < _sensitiveDataFields.length; i++) {
      categories[index++] = _sensitiveDataFields[i]
    }

    const unfilteredCats = [].concat(categories).sort((a, b) => {
      if (a.order === 1) return -1
      if (b.order === 1) return 1
      if (a.order && b.order) return a.order - b.order
      if (a.order) return -1
      if (b.order) return 1
      return a.name.localeCompare(b.name)
    })

    const filteredCats = unfilteredCats.filter((category) => {
      const categoryValues = computedValues[category.id]
      const hasFilterValues = categoryValues && categoryValues.length > 0
      const isFilterEnabled = fieldSettings.value[category.id] ?? true

      return hasFilterValues && isFilterEnabled
    })

    return { unfilteredCategories: unfilteredCats, categories: filteredCats }
  }

  // Set up the watch only once per unique boardId and projectId combination
  watchDebounced(
    [
      departments,
      roles,
      managers,
      locations,
      states,
      sensitiveDataFields,
      workforceStatusList,
      notMappedFields,
      sensitiveDataValues,
      uniqueValues,
      customFieldTypes,
      fieldSettings,
      hierarchyPerson
    ],
    () => {
      const newValues = computeValues()
      const { categories: newCategories, unfilteredCategories: newUnfilteredCategories } = computeCategories(newValues)
      filterValuesStore.setValues(cacheKey, newValues)
      filterValuesStore.setCategories(cacheKey, newCategories)
      filterValuesStore.setUnfilteredCategories(cacheKey, newUnfilteredCategories)
    },
    { immediate: true, debounce: 1000 }
  )

  return { values: computedValues, categories: computedCategories, unfilteredCategories: computedUnfilteredCategories }
}

/**
 * Hook that encapsulates filters categories and values business logic
 * Filter state is stored in useSelectedFilters hook
 */
export function useFilters(boardId, projectId = null) {
  const { values, categories, unfilteredCategories } = createCachedValuesAndCategories(boardId, projectId)

  return {
    categories,
    values,
    unfilteredCategories
  }
}

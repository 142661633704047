<template>
  <div ref="menu" class="relative h-full" data-test="home">
    <HeaderOptionButton
      class="w-auto"
      :is-active="open"
      :icon="{ name: Icon }"
      :is-brandable="true"
      @click="open = !open"
      @mouseover="open = true"
    />

    <transition
      enter-active-class="transition duration-100 origin-top-left"
      enter-from-class="transform opacity-0 scale-50"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition duration-75 origin-bottom-right"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-50"
    >
      <div
        v-if="open"
        class="absolute left-0 z-40 mt-4 min-w-fit rounded-lg border bg-white shadow-lg"
        @mouseleave="open = false"
      >
        <ul class="overflow-y-auto rounded-lg">
          <li v-for="route in routeOptions" :key="route.name">
            <router-link
              v-if="route.show"
              :to="route.url"
              class="inline-block w-full cursor-pointer whitespace-nowrap py-2.5 pl-4 pr-6 text-left text-sm font-normal hover:bg-gray-100"
              :data-test="route.dataTest"
              :class="[$route.fullPath === route.url ? 'text-agentnoon-primary' : 'text-gray-900']"
              @click="open = false"
            >
              {{ route.name }}
            </router-link>
          </li>

          <!-- Contact Support -->
          <li>
            <a
              href="mailto:support@agentnoon.com"
              class="inline-block w-full cursor-pointer whitespace-nowrap py-2.5 pl-4 pr-6 text-left text-sm font-normal text-gray-900 hover:bg-gray-100"
              data-test="contact-support"
            >
              Contact Support
            </a>
          </li>

          <!-- Help center -->
          <li>
            <a
              href="https://docs.agentnoon.com/help-center/articles"
              target="blank"
              class="inline-block w-full cursor-pointer whitespace-nowrap py-2.5 pl-4 pr-6 text-left text-sm font-normal text-gray-900 hover:bg-gray-100"
              data-test="help-center"
            >
              Help Center
            </a>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script setup>
import Icon from '@/assets/untitled-ui/menu-01.svg'
import { onClickOutside } from '@vueuse/core'
import { ref, computed } from 'vue'
import HeaderOptionButton from './HeaderOptionButton.vue'
import { MenuItems } from '@/utils/navigationData'
import useNavigation from '@/hooks/use-navigation'
import useBoard from '@/hooks/use-board'

const open = ref(false)
const menu = ref(null)

const { navigationConfig } = useNavigation()
const { boardId } = useBoard()

const landingUrl = computed(() => `/${boardId.value}`)

const routeOptions = computed(() => [
  {
    show: true,
    url: landingUrl.value,
    name: 'Home',
    dataTest: 'home'
  },
  {
    ...navigationConfig.value[MenuItems.Plans],
    dataTest: 'all-plans'
  },
  {
    ...navigationConfig.value[MenuItems.ApprovedRoles],
    dataTest: 'approved-roles'
  },
  {
    ...navigationConfig.value[MenuItems.Compensation],
    dataTest: 'compensation'
  },
  {
    ...navigationConfig.value[MenuItems.Benchmark],
    dataTest: 'benchmark'
  },
  {
    ...navigationConfig.value[MenuItems.Settings],
    dataTest: 'settings'
  },
  {
    ...navigationConfig.value[MenuItems.Access],
    dataTest: 'access-control'
  }
])

onClickOutside(menu, () => {
  open.value = false
})
</script>

import axios from 'axios'
import { API_URL, getAuthHeader } from '@/services/api.service.js'
import { decompressJson } from '@/utils/Utils.js'

/**
 * Fetches organizational map data for a specific board.
 * @param {Object} params - The parameters for the API request, including boardId.
 * @returns {Promise<Object|null>} - A promise that resolves with the API response or null if an error occurs.
 */
export const fetchOrgmap = async ({ boardId, filters, category, subCategory }) => {
  try {
    const ret = await axios.post(
      `${API_URL}/hub/orgmap/${boardId}`,
      { data: { category, subCategory, filters } },
      { headers: { ...(await getAuthHeader()) } }
    )
    if (ret.status === 200) {
      const data = decompressJson(ret.data || {})
      return data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

/**
 * Fetches people list for a specific span.
 * @param {Object} params - The parameters for the API request, including boardId.
 * @returns {Promise<Object|null>} - A promise that resolves with the API response or null if an error occurs.
 */
export const getSpanPeople = async ({
  boardId,
  category,
  filters,
  from,
  to,
  value,
  queryPlanData
}) => {
  try {
    const ret = await axios.post(
      `${API_URL}/hub/span-of-control/${boardId}/getSpanPeople`,
      { data: { category, filters, from, to, value, queryPlanData } },
      { headers: { ...(await getAuthHeader()) } }
    )
    if (ret.status === 200) {
      const data = decompressJson(ret.data || {})
      return data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

/**
 * Fetches layer and depth data for a specific board.
 * @param {Object} params - The parameters for the API request, including boardId.
 * @returns {Promise<Object|null>} - A promise that resolves with the API response or null if an error occurs.
 */
export const fetchLayerAndDepth = async ({ boardId, filters, category, subCategory }) => {
  try {
    const ret = await axios.post(
      `${API_URL}/hub/layers/${boardId}`,
      { data: { category, filters, subCategory } },
      {
        headers: {
          ...(await getAuthHeader())
        }
      }
    )
    if (ret.status === 200) {
      const data = decompressJson(ret.data || {})
      return data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

/**
 * Fetches layer and span of control distribution data for a specific board.
 * @param {Object} params - The parameters for the API request, including boardId.
 * @returns {Promise<Object|null>} - A promise that resolves with the API response or null if an error occurs.
 */
export const fetchLayerAndSpanOfControl = async ({ boardId, filters, category }) => {
  try {
    const ret = await axios.post(
      `${API_URL}/hub/layers-span-of-control/${boardId}`,
      { data: { category, filters } },
      {
        headers: {
          ...(await getAuthHeader())
        }
      }
    )
    if (ret.status === 200) {
      const data = decompressJson(ret.data || {})
      return data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

/**
 * Fetches distribution data for a specific board.
 * @param {Object} params - The parameters for the API request, including boardId.
 * @returns {Promise<Object|null>} - A promise that resolves with the API response or null if an error occurs.
 */
export const fetchDistribution = async ({ boardId, filters, category }) => {
  try {
    const ret = await axios.post(
      `${API_URL}/hub/distribution/${boardId}`,
      { data: { category, filters } },
      {
        headers: {
          ...(await getAuthHeader())
        }
      }
    )
    if (ret.status === 200) {
      const data = decompressJson(ret.data || {})
      return data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

/**
 * Fetches cost distribution data for a specific board.
 * @param {Object} params - The parameters for the API request, including boardId.
 * @returns {Promise<Object|null>} - A promise that resolves with the API response or null if an error occurs.
 */
export const fetchCostDistribution = async ({ boardId, filters, category, isAverages }) => {
  try {
    const ret = await axios.post(
      `${API_URL}/hub/cost-distribution/${boardId}`,
      { data: { category, filters, isAverages } },
      {
        headers: {
          ...(await getAuthHeader())
        }
      }
    )
    if (ret.status === 200) {
      const data = decompressJson(ret.data || {})
      return data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

/**
 * Fetches growth distribution data for a specific board.
 * @param {Object} params - The parameters for the API request, including boardId.
 * @returns {Promise<Object|null>} - A promise that resolves with the API response or null if an error occurs.
 */
export const fetchGrowthDistribution = async ({ boardId, filters, startDate, endDate }) => {
  try {
    const ret = await axios.post(
      `${API_URL}/hub/growth-distribution/${boardId}`,
      { data: { filters, startDate, endDate } },
      {
        headers: {
          ...(await getAuthHeader())
        }
      }
    )
    if (ret.status === 200) {
      const data = decompressJson(ret.data || {})
      return data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

/**
 * Fetches growth distribution dates for a specific board.
 * @param {Object} params - The parameters for the API request - boardId and filters.
 * @returns {Promise<Object|null>} - A promise that resolves with the API response or null if an error occurs.
 */
export const fetchGrowthDistributionDates = async ({ boardId, filters }) => {
  try {
    const ret = await axios.post(
      `${API_URL}/hub/growth-distribution/${boardId}/dates`,
      { data: { filters } },
      {
        headers: {
          ...(await getAuthHeader())
        }
      }
    )
    if (ret.status === 200) {
      return ret.data || {}
    } else if (ret.status === 400) {
      return {}
    }
    throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

/**
 * Fetches pyramid chart data for a specific board.
 * @param {Object} params - The parameters for the API request, including boardId.
 * @returns {Promise<Object|null>} - A promise that resolves with the API response or null if an error occurs.
 */
export const fetchPyramidChart = async ({ boardId, filters, category, showBy }) => {
  try {
    const ret = await axios.post(
      `${API_URL}/hub/pyramid/${boardId}`,
      { data: { filters, showBy, category } },
      {
        headers: {
          ...(await getAuthHeader())
        }
      }
    )
    if (ret.status === 200) {
      const data = decompressJson(ret.data || {})
      return data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

/**
 * Fetches pyramid chart data for a specific board.
 * @param {Object} params - The parameters for the API request, including boardId.
 * @returns {Promise<Object|null>} - A promise that resolves with the API response or null if an error occurs.
 */
export const updateOrder = async ({ boardId, category, order, hub }) => {
  try {
    const ret = await axios.post(
      `${API_URL}/hub/${boardId}/updateOrder/${hub}`,
      { data: { category, order } },
      {
        headers: {
          ...(await getAuthHeader())
        }
      }
    )
    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

/**
 * Fetches span of control data for a specific board.
 * @param {Object} params - The parameters for the API request, including boardId.
 * @returns {Promise<Object|null>} - A promise that resolves with the API response or null if an error occurs.
 */
export const fetchSpanOfControl = async ({ boardId, filters, category }) => {
  try {
    const ret = await axios.post(
      `${API_URL}/hub/span-of-control/${boardId}`,
      { data: { category, filters } },
      {
        headers: {
          ...(await getAuthHeader())
        }
      }
    )
    if (ret.status === 200) {
      const data = decompressJson(ret.data || {})
      return data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

export const fetchAverageSpanOfControl = async ({ boardId, filters, category }) => {
  try {
    const ret = await axios.post(
      `${API_URL}/hub/average-span-of-control/${boardId}`,
      { data: { category, filters } },
      {
        headers: {
          ...(await getAuthHeader())
        }
      }
    )
    if (ret.status === 200) {
      const data = decompressJson(ret.data || {})
      return data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

/**
 * Fetches icicle chart data for a specific board.
 * @param {Object} params - The parameters for the API request, including boardId.
 * @returns {Promise<Object|null>} - A promise that resolves with the API response or null if an error occurs.
 */
export const fetchIcicle = async ({ boardId, filters }) => {
  try {
    const ret = await axios.post(
      `${API_URL}/hub/icicle/${boardId}`,
      { data: { filters } },
      {
        headers: {
          ...(await getAuthHeader())
        }
      }
    )
    if (ret.status === 200) {
      if (ret.data) {
        return decompressJson(ret.data)
      }
      return {}
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

/**
 * Fetches stacked headcount cost data for a specific board.
 * @param {Object} params - The parameters for the API request, including boardId.
 * @returns {Promise<Object|null>} - A promise that resolves with the API response or null if an error occurs.
 */
export const fetchStackedHeadcountCost = async ({ boardId, filters, category, subCategories }) => {
  try {
    const ret = await axios.post(
      `${API_URL}/hub/stacked-headcount-cost/${boardId}`,
      { data: { category, subCategories, filters } },
      {
        headers: {
          ...(await getAuthHeader())
        }
      }
    )
    if (ret.status === 200) {
      const data = decompressJson(ret.data || {})
      return data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

<template>
  <PopperTooltip
    :enabled="tooltipText"
    :content="tooltipText"
    placement="bottom"
    hover
    open-delay="300"
  >
    <button
      v-bind="$attrs"
      :disabled="disabled"
      :class="[
        'group flex min-w-[36px] cursor-pointer items-center justify-center rounded-lg p-2',
        computedBackgroundClass,
        computedTextClass
      ]"
    >
      <component
        :is="icon.name"
        v-if="icon.name"
        :class="['h-6 w-6', computedGroupHoverClass, iconClass, computedActiveClass]"
      />
      <slot></slot>
    </button>
  </PopperTooltip>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    isActive: {
      type: Boolean,
      default: false
    },
    icon: {
      type: Object,
      default: () => ({ name: null })
    },
    tooltipText: {
      type: String,
      default: ''
    },
    iconClass: {
      type: String,
      default: ''
    },
    isBrandable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    computedGroupHoverClass() {
      return this.isBrandable ? 'group-hover:text-agentnoon-home-button' : 'group-hover:text-bluedark-700'
    },
    computedActiveClass() {
      if (this.isActive) {
        return this.isBrandable ? 'text-agentnoon-home-button' : 'text-bluedark-700'
      } else {
        return this.isBrandable ? 'text-agentnoon-home-button' : 'text-gray-500'
      }
    },
    computedBackgroundClass() {
      if (this.isActive) {
        return this.isBrandable ? 'bg-agentnoon-primary' : 'bg-bluedark-50'
      }
      return ''
    },
    computedTextClass() {
      if (this.isActive) {
        return this.isBrandable ? 'text-agentnoon-home-button' : 'text-bluedark-700'
      }
      return this.isBrandable
        ? 'bg-agentnoon-primary text-agentnoon-home-button'
        : 'hover:bg-bluedark-50 hover:text-bluedark-700 text-gray-500'
    }
  }
}
</script>
